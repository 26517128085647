import React from "react";
import { format, colors } from "utils";
import "./ComplaintsLineChart.scss";
import { CommercialSupplyComplaintsChartData } from "api/commercial-item/commercial-supply";
import ChartTitle from "common-components/chart-title/ChartTitle";
import { LineChart } from "@opsdti-global-component-library/amgen-design-system";

const namespace = "commercial-modal-supply-complaints";

type ComplaintsLineChartPropsType = {
  data: CommercialSupplyComplaintsChartData[];
};

/* Function to grabs every other month label */
function getCustomTicks(data: CommercialSupplyComplaintsChartData[]): string[] {
  const labels: string[] = [];
  data.forEach(
    (datapoint: CommercialSupplyComplaintsChartData, index: number) => {
      if (index % 2 === 0) {
        labels.push(datapoint.label);
      }
    }
  );
  return labels;
}

function ComplaintsLineChart(props: ComplaintsLineChartPropsType) {
  return (
    <div className={namespace}>
      <ChartTitle
        title="SURECLICK COMPLAINT ISSUES PER MILLION BY PRODUCT"
        subtitle="Complaint Issues Per Million doses distribution (3 month rolling average)"
      />
      <LineChart
        className={`${namespace}-chart`}
        data={props.data}
        xKey="label"
        dataKeys={[
          {
            lineKey: "actual",
            dot: true,
            color: colors.purple
          }
        ]}
        chartHeight={175}
        showPopover
        yAxisWidth={43}
        customXTicks={getCustomTicks(props.data)}
        showLegend
        xAxisTickFormatter={(
          value: string | number | Array<string | number>
        ) => {
          if (typeof value === "number") {
            return format.simple(value, { padZeroIfDecimal: true });
          }

          return value.toString();
        }}
        yAxisTickFormatter={(
          value: string | number | Array<string | number>
        ) => {
          if (typeof value === "number") {
            return format.simple(value, { decimals: 2 });
          }
          return value.toString();
        }}
      />
    </div>
  );
}

export default ComplaintsLineChart;

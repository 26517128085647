import React from "react";
import "./ComModalSupplyComplaints.scss";
import { InactiveSection } from "api/common";
import {
  Table,
  Tabs
} from "@opsdti-global-component-library/amgen-design-system";
import ComplaintsLineChart from "./ComplaintsLineChart/ComplaintsLineChart";
import { CommercialSupplyComplaintsSection } from "api";
import { AccordionWithStatusV2 } from "common-components/accordion-with-status-v2/AccordionWithStatusV2";

const namespace = "rts-pa-commercial-modal-supply-complaints";

type Props = {
  data: CommercialSupplyComplaintsSection | InactiveSection | undefined | null;
};

const ComModalSupplyComplaints = (props: Props) => {
  const title = "Complaints";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return <AccordionWithStatusV2 title={title} status="gray" />;
  }

  return (
    <AccordionWithStatusV2
      status={props.data.status}
      title={title}
      className={namespace}
      accordionSyncKey="CommercialModal"
      links={props.data.links}
    >
      <Tabs
        items={[
          {
            children: <ComplaintsLineChart data={props.data.yearlyData} />,
            key: "1",
            label: "Yearly"
          },
          {
            children: <ComplaintsLineChart data={props.data.monthlyData} />,
            key: "2",
            label: "Last 12 Months"
          }
        ]}
      />

      <Table
        className="rts-pa-commercial-modal-supply-complaints-table"
        columns={[
          {
            dataIndex: "product",
            key: "product",
            title: props.data.table[0].headers[0]
          },
          {
            dataIndex: "yearEndBaseline",
            key: "yearEndBaseline",
            title: props.data.table[0].headers[1]
          },
          {
            dataIndex: "prevMonthActual",
            key: "prevMonthActual",
            title: props.data.table[0].headers[2]
          },
          {
            dataIndex: "currMonthActual",
            key: "currMonthActual",
            title: props.data.table[0].headers[3]
          }
        ]}
        dataSource={[
          {
            product: props.data.table[0].rows[0],
            yearEndBaseline: props.data.table[0].rows[1],
            prevMonthActual: props.data.table[0].rows[2],
            currMonthActual: props.data.table[0].rows[3] // next month actual?
          }
        ]}
      />
    </AccordionWithStatusV2>
  );
};

export default ComModalSupplyComplaints;

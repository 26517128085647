import React, { useMemo } from "react";
import "./KeyEventsCalendar.scss";
import KeyEventsCalendarHeader from "./header/KeyEventsCalendarHeader";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { getCalendarBoard } from "api/calendar-board";
import {
  CalendarFilters,
  Columns,
  MilestoneFixed,
  MilestoneRange,
  Quarter,
  RowItem,
  Year
} from "./calendarTypes";
import KeyEventsCalendarRow from "./row/KeyEventsCalendarRow";
import useLimitedView from "global/use-limited-view";
import pages from "pages/pages";
import KeyEventsCalendarEmptyContainer from "./empty-container/KeyEventsCalendarEmptyContainer";
import { CalendarMilestone } from "api";

export const namespace = "rts-pa-key-events-calendar";

type Props = {
  filters: CalendarFilters;
};

export default function KeyEventsCalendar(props: Props): JSX.Element {
  const { isLimitedView } = useLimitedView(pages.keyEvents);
  const { data } = useQuery(
    [
      "calendar-board",
      isLimitedView,
      props.filters.startDateMin,
      props.filters.startDateMax
    ],
    () =>
      getCalendarBoard(
        isLimitedView,
        props.filters.startDateMin,
        props.filters.startDateMax
      )
  );

  const columns: Columns = useMemo((): Columns => {
    if (!data) {
      return {
        years: [],
        gridTemplateColumns: ""
      };
    }

    const years: Year[] = [];
    let gridTemplateColumns = "";
    for (const yearRange of data.range) {
      const year: Year = {
        year: yearRange.year,
        quarters: []
      };

      years.push(year);

      for (const quarterRange of yearRange.quarters) {
        const quarter: Quarter = {
          num: quarterRange,
          months: []
        };

        year.quarters.push(quarter);

        for (let i = 1; i <= 3; i++) {
          const month = i + (quarter.num - 1) * 3;
          quarter.months.push(month);
          gridTemplateColumns += ` [y${year.year}m${month}] 1fr`;
        }
      }
    }

    return {
      years: years,
      gridTemplateColumns: gridTemplateColumns
    };
  }, [data]);

  const rows: RowItem[] = useMemo(() => {
    if (!data || !columns) {
      return [];
    }

    const rows: RowItem[] = [];

    for (const apiRow of data.rows) {
      // add row item
      const rowItem: RowItem = {
        id: apiRow.id,
        name: apiRow.name,
        linkToSource: apiRow.linkToSource,
        order: apiRow.order,
        fixedMilestones: [],
        rangeMilestones: []
      };

      rows.push(rowItem);

      // create milestone mappers

      const mapToFixedMilestone = (
        apiMilestone: CalendarMilestone
      ): MilestoneFixed => {
        const startDate = dayjs(apiMilestone.startDate);

        const milestone: MilestoneFixed = {
          id: apiMilestone.id,
          milestoneCalendarKeyId: apiMilestone.milestoneCalendarKeyId,
          name: apiMilestone.name,
          text: apiMilestone.text,
          startDate: startDate.format("MMM 'YY"),
          startYear: startDate.year(),
          startMonth: startDate.month() + 1
        };

        return milestone;
      };

      const mapToRangeMilestone = (
        apiMilestone: CalendarMilestone
      ): MilestoneRange => {
        const startDate = dayjs(apiMilestone.startDate);
        const endDate = dayjs(apiMilestone.endDate);

        const min = columns.years[0];

        let startYear = startDate.year();
        let startMonth = startDate.month() + 1;

        const endYear = endDate.year();
        const endMonth = endDate.month() + 1;

        // correct min range
        if (
          startYear < min.year ||
          (startYear === min.year && startMonth < min.quarters[0].months[0])
        ) {
          startYear = min.year;
          startMonth = min.quarters[0].months[0];
        }

        // note: max range handled fine by CSS even when incorrect column name

        const milestone: MilestoneRange = {
          id: apiMilestone.id,
          milestoneCalendarKeyId: apiMilestone.milestoneCalendarKeyId,
          name: apiMilestone.name,
          text: apiMilestone.text,
          startDate: startDate.format("MMM 'YY"),
          startYear: startYear,
          startMonth: startMonth,
          endYear: endYear,
          endMonth: endMonth
        };

        return milestone;
      };

      // map milestones to calendar row
      for (const apiMilestone of apiRow.milestones) {
        if (!apiMilestone.endDate) {
          // fixed
          rowItem.fixedMilestones.push(mapToFixedMilestone(apiMilestone));
        } else {
          // range
          rowItem.rangeMilestones.push(mapToRangeMilestone(apiMilestone));
        }
      }
    }

    return rows;
  }, [data, columns]);

  return (
    <div className={namespace}>
      <KeyEventsCalendarHeader columns={columns} />
      {rows.length ? (
        rows.map((r, i) => (
          <KeyEventsCalendarRow
            key={r.id}
            item={r}
            columns={columns}
            index={i}
          />
        ))
      ) : (
        <KeyEventsCalendarEmptyContainer filters={props.filters} />
      )}
    </div>
  );
}

import React from "react";
import "./KeyEventsCalendarRowMilestoneRange.scss";
import { MilestoneRange } from "components/key-events-calendar/calendarTypes";
import { Text } from "common-components";

export const namespace = "rts-pa-key-events-calendar-row-milestone-range";

type Props = {
  milestone: MilestoneRange;
};

export default function KeyEventsCalendarRowMilestoneRange(
  props: Props
): JSX.Element {
  return (
    <div
      className={namespace}
      style={{
        gridColumn: `y${props.milestone.startYear}m${props.milestone.startMonth} / y${props.milestone.endYear}m${props.milestone.endMonth}`
      }}
    >
      <div className={`${namespace}-container`}>
        <Text className={`${namespace}-container-text`}>
          {props.milestone.name}
        </Text>
      </div>
    </div>
  );
}

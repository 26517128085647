import React, { useContext, useState } from "react";
import "./KeyEventsPage.scss";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import { Container } from "@gitlab-rtsensing/component-library";
import KeyEventsPageHeader from "./header/KeyEventsPageHeader";
import KeyEventsCalendar from "components/key-events-calendar/KeyEventsCalendar";
import { CalendarFilters } from "components/key-events-calendar/calendarTypes";
import {
  getQuarterEnd,
  getQuarterStart
} from "components/key-events-calendar/calendarUtils";
import UpsertCalendarModal from "components/upsert-calendar-modal/UpsertCalendarModal";
import UpsertCalendarRowModal from "components/upsert-calendar-row-modal/UpsertCalendarRowModal";
import produce from "immer";

const namespace = "rts-pa-key-events-page";

export default function KeyEventsPage(): JSX.Element {
  const isLoaded = useContext(PermissionsContext).isLoaded;

  const [filters, setFilters] = useState<CalendarFilters>({});

  return (
    <>
      <div className={namespace}>
        <PermissionsWrapper permissions={isLoaded}>
          <Container className={`${namespace}-container`}>
            <KeyEventsPageHeader
              dateRange={
                filters.startDateMin && filters.startDateMax
                  ? [filters.startDateMin, filters.startDateMax]
                  : []
              }
              onDateRangeChange={dateRange => {
                const newFilters = produce(filters, draftState => {
                  draftState.startDateMin = getQuarterStart(dateRange[0]);
                  draftState.startDateMax = getQuarterEnd(dateRange[1]);
                });

                setFilters(newFilters);
              }}
            />
            <KeyEventsCalendar filters={filters} />
          </Container>
        </PermissionsWrapper>
      </div>
      <PermissionsWrapper permissions={isLoaded}>
        <UpsertCalendarRowModal />
        <UpsertCalendarModal />
      </PermissionsWrapper>
    </>
  );
}

import React from "react";
import {
  ChartTitle as DSChartTitle,
  ChartTitleProps
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";

const namespace = "rts-pa-chart-title";

type Props = ChartTitleProps;

export const ChartTitle = (props: Props) => {
  const className = classNames(namespace, props.className);

  return <DSChartTitle {...props} className={className} />;
};

export default ChartTitle;

import React from "react";
import "./KeyEventsCalendarRowMilestoneFixed.scss";
import { MilestoneFixed } from "components/key-events-calendar/calendarTypes";
import { MilestoneTypeIcon, Text } from "common-components";

export const namespace = "rts-pa-key-events-calendar-row-milestone-fixed";

type Props = {
  milestone: MilestoneFixed;
};

export default function KeyEventsCalendarRowMilestoneFixed(
  props: Props
): JSX.Element {
  return (
    <div
      className={namespace}
      style={{
        gridColumn: `y${props.milestone.startYear}m${props.milestone.startMonth} / span 6`
      }}
    >
      <MilestoneTypeIcon
        id={props.milestone.milestoneCalendarKeyId}
        size="large"
      />
      <div className={`${namespace}-container`}>
        <Text className={`${namespace}-text`}>{props.milestone.name}</Text>
        <Text className={`${namespace}-date`}>{props.milestone.startDate}</Text>
      </div>
    </div>
  );
}

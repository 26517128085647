import React from "react";
import "./KeyEventsCalendarRow.scss";
import { Columns, RowItem } from "../calendarTypes";
import { Text } from "common-components";
import KeyEventsCalendarRowBorder from "./border/KeyEventsCalendarRowBorder";
import KeyEventsCalendarRowMilestoneFixed from "./milestone/fixed/KeyEventsCalendarRowMilestoneFixed";
import KeyEventsCalendarRowMilestoneRange from "./milestone/range/KeyEventsCalendarRowMilestoneRange";
import classNames from "classnames";
import KeyEventsCalendarRowDateline from "./dateline/KeyEventsCalendarRowDateline";
import KeyEventsCalendarRowMenu from "./menu/KeyEventsCalendarRowMenu";

export const namespace = "rts-pa-key-events-calendar-row";

type Props = {
  columns: Columns;
  item: RowItem;
  index: number;
};

export default function KeyEventsCalendarRow(props: Props): JSX.Element {
  const rightContanerClassName = classNames(`${namespace}-right-container`, {
    odd: props.index % 2 === 1
  });

  return (
    <div className={namespace}>
      <div className={`${namespace}-left-container`}>
        <Text>{props.item.name}</Text>
        <KeyEventsCalendarRowMenu
          rowId={props.item.id}
          linkToSource={props.item.linkToSource}
        />
      </div>
      <div className={rightContanerClassName}>
        <div
          className={`${namespace}-right-container-fixed`}
          style={{ gridTemplateColumns: props.columns.gridTemplateColumns }}
        >
          {props.item.fixedMilestones.map(m => (
            <KeyEventsCalendarRowMilestoneFixed key={m.id} milestone={m} />
          ))}
          {props.columns.years.map((y, i) =>
            props.columns.years.length - 1 !== i ? (
              <KeyEventsCalendarRowBorder key={y.year} year={y.year} />
            ) : null
          )}
          <KeyEventsCalendarRowDateline />
        </div>
        <div
          className={`${namespace}-right-container-range`}
          style={{
            gridTemplateColumns: props.columns.gridTemplateColumns,
            display: !props.item.rangeMilestones.length ? "none" : "inherit"
          }}
        >
          {props.item.rangeMilestones.map(m => (
            <KeyEventsCalendarRowMilestoneRange key={m.id} milestone={m} />
          ))}
          {props.columns.years.map((y, i) =>
            props.columns.years.length - 1 !== i ? (
              <KeyEventsCalendarRowBorder key={y.year} year={y.year} />
            ) : null
          )}
          <KeyEventsCalendarRowDateline />
        </div>
      </div>
    </div>
  );
}

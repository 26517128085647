import dayjs, { Dayjs } from "dayjs";

export const getQuarterStart = (date: Dayjs | undefined): Dayjs | undefined => {
  if (!date) {
    return undefined;
  }

  const quarterMonthStart = Math.floor(date.month() / 3) * 3;
  return dayjs(new Date(date.year(), quarterMonthStart, 1));
};

export const getQuarterEnd = (date: Dayjs | undefined): Dayjs | undefined => {
  const startDate = getQuarterStart(date);

  if (!startDate) {
    return undefined;
  }

  return startDate.add(3, "months").subtract(1, "day");
};
